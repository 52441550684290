<template>
  <div>
    <c-table
      ref="table"
      title="부담작업표"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :editable="editable"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'worksheetMasterManage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'heavyWorkName',
            field: 'heavyWorkName',
            label: '부담<br/>작업명',
            align: 'center',
            style: 'width: 70px',
            sortable: true,
          },
          {
            name: 'dayWorkTime',
            field: 'dayWorkTime',
            label: '일근무시간',
            align: 'right',
            type: 'number',
            style: 'width: 70px',
            sortable: true,
            required: true
          },
          {
            name: 'exposureTime',
            field: 'exposureTime',
            label: '노출시간',
            align: 'right',
            type: 'cost',
            style: 'width: 70px',
            sortable: true,
          },
          {
            name: 'exopsureCnt',
            field: 'exopsureCnt',
            label: '노출빈도<br/>(횟수)',
            align: 'right',
            type: 'cost',
            style: 'width: 70px',
            sortable: true,
          },
          {
            name: 'exopsureWeight',
            field: 'exopsureWeight',
            label: '노출취급중량<br/>(kg)',
            align: 'right',
            type: 'cost',
            style: 'width: 70px',
            sortable: true,
          },
          {
            name: 'exopsureRate',
            field: 'exopsureRate',
            label: '일작업<br/>부담비율',
            align: 'right',
            type: 'cost',
            style: 'width: 70px',
            sortable: true,
          },
          {
            name: 'bodyDesc',
            field: 'bodyDesc',
            label: '신체부위',
            align: 'left',
            style: 'width: 250px',
            sortable: true,
          },
          {
            name: 'positionDesc',
            field: 'positionDesc',
            label: '작업자세 및 내용',
            align: 'left',
            style: 'width: 350px',
            sortable: true,
          },
          {
            name: 'weightDesc',
            field: 'weightDesc',
            label: '무게',
            align: 'left',
            style: 'width: 200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      isSave: false,
      saveUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.heavyWork.list.url;
      this.saveUrl = transactionConfig.hea.heavyWork.update.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    tableDataChange(props) {
      let exopsureRate = 0;
      if (props.row.dayWorkTime && props.row.dayWorkTime > 0) {
        exopsureRate = this.$_.round(props.row.exposureTime / props.row.dayWorkTime, 2)
      }
      this.$set(props.row, 'exopsureRate', exopsureRate)
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>
